import React, { useRef } from "react";

export default function Modal({ title, description, children, open, onClose, footer }) {
  const modalRef = useRef(null);

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      onClose();
    }
  };

  return open ? (
    <div className="modal-open" onClick={handleOutsideClick}>
      <div className="fade modal-backdrop in" />
      <div className="modal fade in" tabIndex="-1" role="dialog" style={{ display: "block" }}>
        <div className="modal-dialog" role="document" ref={modalRef}>
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" aria-label="Close" onClick={onClose}>
                <span aria-hidden="true">&times;</span>
              </button>
              {title && <h4 className="modal-title">{title}</h4>}
              {description}
            </div>
            <div className="modal-body">{children}</div>
            {footer && <div className="modal-footer">{footer}</div>}
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

Modal.defaultProps = {
  open: false,
};
